.Answers {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Answers li {
    padding: 10px;

    background-color: #fff;
    margin: 0px 0px 10px;
    border-radius: 50px;
    color: black;
    padding-left: 23px;
}

.Answers li:hover {
    cursor: pointer;
    background-color: #a2a2a2;
}

.Answers .correct,
.Answers .correct:hover {
    background-color: #1db900;

    color: #fff;
}

.Answers .incorrect,
.Answers .incorrect:hover {
    background-color: #ec0000;

    color: #fff;
}

.Answers[disabled] {
    pointer-events: none;
    opacity: 0.7;
}