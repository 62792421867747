.NextStep {
    background-color: #A1E44B;
    color: #fff;
    padding: 10px 30px;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
}

.NextStep:hover {
    background-color: #A1E44B;
}

.NextStep[disabled] {
    display: none;
}

.finalPage {
    text-align: center;
}

.finalPage h1 {
    text-align: center;
    margin: 15px 0;
    font-size: 25px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    color: #A1E44B;
    text-transform: none;
    text-shadow: none;
}